<template>
  <!-- <div> -->
  <div class="video-wrapper" @mouseover="showTools = true" @mouseout="showTools = false" ref="videoModulElement">
    <!-- <video muted autoplay ref="videoElement" v-show="playing" class="player"></video> -->
    <a-spin tip="视频加载中..." :spinning="loading">
      <video
        :ref="'videoElement-' + index"
        v-if="videoType === 'rtmp' && playing && !isLive && reLoadVideo"
        class="video-js player"
      >
        <!-- <source :src="url" v-if="url" type="video/mp4" />
        <source :src="url" v-if="url" type="video/webm" /> -->
      </video>
      <ali-player
        class="player"
        ref="player"
        :options="options"
        @ready="handleReady"
        @error="handleError"
        @ended="handleEnded"
        :source="url"
        v-if="videoType === 'rtmp' && isLive && url"
      />
      <RtcVideoPlay
        v-if="videoType === 'rtc' && playing"
        ref="rtcVideo"
        :vehicleId="vehicleId"
        @onConnectType="onConnectType"
        :rtcInfo="rtcInfo"
        :vehicleCameraDeviceInfo="vehicleCameraDeviceInfo"
      ></RtcVideoPlay>
    </a-spin>
    <div class="video-tool" v-if="videoType === 'rtc'" v-show="showTools">
      <div class="tool-item" v-if="showScreenBtn" @mouseover="showTools = true" @click="fullScreen()" title="全屏">
        <img class="img" src="@/assets/images/video/full-screen.png" alt="" />
      </div>
      <div class="tool-item" @mouseover="showTools = true" @click="refresh()" title="刷新">
        <img class="img" src="@/assets/images/video/refresh.png" alt="" />
      </div>
    </div>
    <!-- <div v-show="!playing" class="video-mask">视频已关闭</div> -->
    <!-- 隐藏视频开关功能，目前不需要
    <a-switch
      :loading="loading"
      size="small"
      @change="switchChange"
      class="video-switch"
      checked-children="开"
      un-checked-children="关"
      v-model="checked"
    /> -->
  </div>
  <!-- <div v-else-if="videoType === 'rtc'">
      <RtcVideoPlay></RtcVideoPlay>
    </div> -->
  <!-- </div> -->
</template>

<script>
import VueAliplayerV2 from 'vue-aliplayer-v2'
import RtcVideoPlay from './RtcVideoPlay'
import videojs from 'video.js'
import 'video.js/dist/video-js.css'

// import get from 'lodash/get'
export default {
  name: 'RevoVideoPlayer',
  props: {
    // vehicleId: {
    //   type: String,
    //   default: ''
    // },
    direction: {
      type: String,
      default: ''
    },
    url: {
      type: String,
      default: ''
    },
    isLive: {
      type: Boolean,
      default: true
    },
    progressMarkers: {
      type: Array,
      default: undefined
    },
    seekTime: {
      type: Number,
      default: undefined
    },
    videoType: {
      type: String,
      default: 'rtmp'
    },
    index: {
      type: Number,
      default: undefined
    },
    vehicleId: {
      type: String,
      default: ''
    },
    rtcInfo: {
      type: Object,
      default: () => {}
    },
    vehicleCameraDeviceInfo: {
      type: Object,
      default: () => {}
    },
    isSyncVideo: {
      type: Boolean,
      default: false
    },
    cameraTitle: {
      type: String,
      default: ''
    },
    showScreenBtn: {
      type: Boolean,
      default: false
    }
  },
  components: {
    'ali-player': VueAliplayerV2,
    RtcVideoPlay
  },
  data() {
    return {
      flvPlayer: null,
      playing: false,
      loading: false,
      showTools: false,
      initPlay: true,
      checked: true,
      flvPlayerTimer: null,
      lastDecodedFrame: 0,
      videoFirsetLoad: true,
      reLoadVideo: true,
      options: {},
      skinLayout: [
        { name: 'bigPlayButton', align: 'blabs', x: 30, y: 80 },
        {
          name: 'H5Loading',
          align: 'cc'
        },
        { name: 'errorDisplay', align: 'tlabs', x: 0, y: 0 },
        { name: 'infoDisplay' },
        { name: 'tooltip', align: 'blabs', x: 0, y: 56 },
        { name: 'thumbnail' },
        {
          name: 'controlBar',
          align: 'blabs',
          x: 0,
          y: 0,
          children: [
            { name: 'progress', align: 'blabs', x: 0, y: 44 },
            { name: 'playButton', align: 'tl', x: 15, y: 12 },
            { name: 'timeDisplay', align: 'tl', x: 10, y: 7 },
            { name: 'fullScreenButton', align: 'tr', x: 10, y: 12 }
          ]
        }
      ],
      skinLayoutBySync: [
        {
          name: 'controlBar',
          align: 'blabs',
          x: 0,
          y: 0,
          children: [{ name: 'fullScreenButton', align: 'tr', x: 10, y: 12 }]
        }
      ]
    }
  },
  computed: {},
  watch: {
    url: function (newValue, oldValue) {
      console.log('视频播放地址发生变化', newValue, ' **', this.videoFirsetLoad)
      if (newValue !== oldValue && (!this.videoFirsetLoad || !this.isLive)) {
        this.$nextTick(() => {
          console.log('this.player:', this.player)
          if (this.player && this.player.isDisposed_) {
            this.handleError()
          } else {
            // 上一句已判断是否已释放，所以此行代码放于 else语句中
            this.player && this.player.dispose && this.player.dispose()
          }
          this.reLoadVideo = false
          this.$nextTick(() => {
            this.reLoadVideo = true
            this.play()
          })
        })
      }
    },
    progressMarkers: function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.options.progressMarkers = this.progressMarkers || []
      }
    },
    videoType(val) {
      console.log('videoType ****', val)
      if (val === 'rtc') {
        this.flvPlayerTimer && clearInterval(this.flvPlayerTimer)
        this.openRtc()
      } else {
        this.play()
      }
    },
    isSyncVideo(val) {
      if (val) {
        this.options.skinLayout = this.skinLayoutBySync
      }
    }
    // direction: function (newValue, oldValue) {
    //   console.log('direction new: ', newValue)
    //   console.log('direction old: ', oldValue)
    // }
  },
  created() {
    this.options = {
        // source:'//player.alicdn.com/video/aliyunmedia.mp4',
        // 切换为直播流的时候必填
        isLive: this.isLive,
        height: '100%',
        width: '100%',
        defaultDefinition: 'FD',
        enableStashBufferForFlv: false,
        // 切换为直播流的时候必填
        format: 'flv',
        autoplay: true,
        showBuffer: false
      }
  },
  mounted() {
    console.log('index **** ', this.isLive)
    if (this.progressMarkers) {
      this.options.progressMarkers = this.progressMarkers
    }
    if (!this.isLive) {
      if (this.isSyncVideo) {
        this.options.skinLayout = this.skinLayoutBySync
      }
      this.$nextTick(() => {
        this.play('init')
      })
    } else {
      if (this.videoType === 'rtc') {
        this.openRtc()
      } else {
        this.options.skinLayout = this.isSyncVideo ? this.skinLayoutBySync : this.skinLayout
      }
    }
  },
  beforeDestroy() {
    this.destroySource()
  },
  methods: {
    handleReady(e) {
      console.log('视频初始化成功', e)
      this.videoFirsetLoad = false
      // this.$nextTick(() => {
      //   this.play()
      // })
      let duration
      if (this.player) {
        duration = this.getDuration()
        console.log('duration', duration)
      }
      this.$emit('handleReady', duration)
      if (!this.isLive) {
        this.getCurrentTime()
      }
    },
    getDuration() {
      return this.player ? this.player.duration() : ''
    },
    getCurrentTime() {
      const player = this.player
      const n = player.currentTime()
      this.ctTimeOut = setTimeout(() => {
        if (player.isDisposed_) {
          this.handleError()
          return
        }
        const l = player.currentTime && player.currentTime()
        console.log('getCurrentTime * n:', n)
        console.log('getCurrentTime * l:', l)
        if (n === l) {
          this.getCurrentTime()
          this.cf = true
          this.$emit('isErrorPlay', 'start')
        } else {
          if (this.cf) {
            this.$emit('isErrorPlay', 'end')
            this.cf = false
          }
        }
      }, 2000)
    },
    handlePlaying(e) {
      console.log('handlePlaying *****', e)
      this.$emit('handlePlaying', e)
    },
    handleEnded(e) {
      console.log('handleEnded *****', e)
      clearTimeout(this.ctTimeOut)
      this.$emit('handleEnded', e)
    },
    openRtc() {
      this.playing = true
      this.$nextTick(() => {
        this.$refs.rtcVideo.init()
      })
    },
    play(type) {
      console.log('init play ***********')
      if (this.url && this.videoType === 'rtmp') {
        this.openAli(type)
      }
    },
    switchChange(checked) {
      this.checked = checked
      if (checked) {
        this.playing = true
        this.openAli()
      } else {
        this.playing = false
        this.stop()
      }
    },
    stop() {
      const player = this.isLive ? this.$refs.player : this.player
      setTimeout(() => {
        try {
          // 加上catch，避免中断，但是否有效需要待验证
          player && player.pause && player.pause()
          player && player.dispose && player.dispose()
        } catch (e) {
          console.log('暂停视频播放失败', e)
        }
      }, 50)
    },
    pause() {
      const player = this.isLive ? this.$refs.player : this.player
      if (player && player.pause) {
        setTimeout(() => {
          try {
            // 加上catch，避免中断，但是否有效需要待验证
            player.pause()
          } catch (e) {
            console.log('暂停视频播放失败', e)
          }
        }, 50)
      }
    },
    isPlay() {
      const player = this.isLive ? this.$refs.player : this.player
      console.log('isPlay *****')
      player && player.play()
    },
    seek(time) {
      // time单位为秒
      this.player && this.player.currentTime(time)
    },
    // 解决flv.js调用播放延时，但是会有跳帧现象
    // TODO: 还需解决播放延时，但是会有跳帧现象
    fixFlvPlayerDelay() {
      this.flvPlayerTimer && clearInterval(this.flvPlayerTimer)
      this.flvPlayerTimer = setInterval(() => {
        try {
          if (this.flvPlayer.buffered.length) {
            // console.log('flv buffered.length===', this.flvPlayer.buffered.length)
            // 获取当前buffered值
            const end = this.flvPlayer.buffered.end(0)
            // console.log('flv buffered end===', end)
            // 获取buffered与currentTime的差值
            const diff = end - this.flvPlayer.currentTime
            // console.log('flv buffered diff===', diff)
            // 如果差值大于等于0.5 手动跳帧 这里可根据自身需求来定
            if (diff >= 1) {
              // console.log('diff int:', Math.floor(diff))
              // 手动跳帧
              this.flvPlayer.currentTime = end - 0.00000000001
              //  - (diff - Math.floor(diff))
            }
          }
        } catch (error) {
          console.error('flv player found error:', error)
        }
        // 2000毫秒执行一次
      }, 1000)
    },
    // 解决flv.js画面卡死
    fixFlvPlayStick() {
      this.flvPlayer.on('statistics_info', function (res) {
        if (this.lastDecodedFrame === 0) {
          this.lastDecodedFrame = res.decodedFrames
          return
        }
        if (this.lastDecodedFrame !== res.decodedFrames) {
          this.lastDecodedFrame = res.decodedFrames
        } else {
          this.lastDecodedFrame = 0
          if (this.flvPlayer) {
            this.flvPlayer.pause()
            this.flvPlayer.unload()
            this.flvPlayer.detachMediaElement()
            this.flvPlayer.destroy()
            this.flvPlayer = null
            this.openAli()
          }
        }
      })
    },
    openAli(type) {
      if (this.videoType === 'rtc') {
        return
      }
      // console.log('player-ali', player)
      // if (type !== 'init') {
      //   setTimeout(() => {
      //     // 跳转播放
      //     this.seekTime && player && player.seek(this.seekTime)
      //     player && player.play()
      //   }, 0)
      // }
      // player && (this.seekTime ? player.seek(this.seekTime) : player.play())
      this.playing = true
      this.loading = false
      // setTimeout(() => {}, 300)
      if (this.isLive) {
        const player = this.$refs.player
        if (player && player.player && player.player._flv) {
          // 获取阿里播放器内置的flv.js对象，需要在播放器初始化完成回调中获取，否则为空
          this.flvPlayer = player.player._flv
          console.log('flvPlayer', this.flvPlayer)
          this.fixFlvPlayerDelay()
          this.fixFlvPlayStick()
        }
      } else {
        this.$nextTick(() => {
          if (this.url && this.url.indexOf('.mp4') > -1) {
            this.creatVideo()
          }
        })
      }
    },
    creatVideo() {
      const that = this
      const debuger = false
      this.player = videojs(
        this.$refs['videoElement-' + this.index],
        {
          bigPlayButton: false,
          autoplay: false,
          controls: !this.isSyncVideo,
          playbackRates: [0.5, 1, 1.2, 1.5, 2],
          sources: [
            {
              src: this.url,
              type: 'video/mp4'
            }
          ]
        },
        function onPlayerReady() {
          // In this context, `this` is the player that was created by Video.js.<br>  // 注意，这个地方的上下文， `this` 指向的是Video.js的实例对像player
          debuger && console.log(that.cameraTitle + '-onPlayerReady', that.seekTime)
          // that.$nextTick(() => {
          // })
          // How about an event listener?<br>  // 如何使用事件监听？
          this.on('loadstart', function () {
            // console.log('开始请求数据 ')
          })
          this.on('progress', function () {
            // console.log('正在请求数据 ')
          })
          this.on('loadedmetadata', function () {
            // console.log('获取资源长度完成 ')
            that.seekTime && this.currentTime(that.seekTime)
            this.play()
          })
          this.on('canplaythrough', function () {
            // console.log('视频源数据加载完成')
          })
          this.on('waiting', function () {
            // console.log('等待数据')
          })
          this.on('play', function (e) {
            debuger && console.log(that.cameraTitle + '-视频开始播放')
            that.handleReady(e)
          })
          this.on('playing', function () {
            // console.log('视频播放中')
          })
          this.on('pause', function () {
            // console.log(that.cameraTitle + '-视频暂停播放')
          })
          this.on('ended', function (e) {
            debuger && console.log(that.cameraTitle + '-视频播放结束')
            that.handleEnded(e)
          })
          this.on('error', function (e) {
            console.log(that.cameraTitle + '-加载错误:', e)
            // this.pause()
            // this.dispose()
            // this.play()
            const currentTime = this.currentTime()
            that.stop()
            // setTimeout(() => {
            //   that.creatVideo()
            //   that.seek(currentTime + 1)
            // }, 50)
            that.reLoadVideo = false
            that.$nextTick(() => {
              that.reLoadVideo = true
              that.play()
              setTimeout(() => {
                that.seek(currentTime + 1)
              }, 50)
            })
            that.handleError(e)
          })
          this.on('seeking', function () {
            debuger && console.log(that.cameraTitle + '-视频跳转中')
          })
          this.on('seeked', function () {
            debuger && console.log(that.cameraTitle + '-视频跳转结束')
          })
          this.on('ratechange', function () {
            // console.log('播放速率改变')
          })
          this.on('timeupdate', function () {
            // console.log('播放时长改变')
          })
          this.on('volumechange', function () {
            debuger && console.log('音量改变')
          })
          this.on('stalled', function () {
            console.log(that.cameraTitle + '-网速异常')
          })
        }
      )

      // videojs设置中文
      videojs.addLanguage('zh-CN', {
        'You aborted the media playback': '视频播放被终止',

        'A network error caused the media download to fail part-way.': '网络错误导致视频下载中途失败。',

        'The media could not be loaded, either because the server or network failed or because the format is not supported.':
          '视频因格式不支持或者服务器或网络的问题无法加载。',

        'The media playback was aborted due to a corruption problem or because the media used features your browser did not support.':
          '由于视频文件损坏或是该视频使用了你的浏览器不支持的功能，播放终止。',

        'No compatible source was found for this media.': '无法找到此视频兼容的源。'
      })
    },
    setSpeed(speed) {
      // 设置播放倍数
      const player = this.player
      player && player.playbackRate && player.playbackRate(speed)
    },
    destroySource() {
      console.log('destroySource vedio ......')
      this.flvPlayerTimer && clearInterval(this.flvPlayerTimer)
      this.stop()
    },
    reset() {
      console.log('reset vedio ......')
      this.flvPlayerTimer && clearInterval(this.flvPlayerTimer)
      this.playing = false
    },
    handleError(e) {
      console.log('handleError', e)
      console.log(new Date())
      this.$emit('handleError', { response: e, direction: this.direction })
    },
    onConnectType(type) {
      // rtc连接回调
      switch (type) {
        case 'init':
          this.playing = true
          this.loading = true
          break
        case 'join':
          this.playing = true
          this.loading = false
          break
        case 'disconnect':
          this.playing = false
          this.loading = false
          break
        case 'error':
          this.playing = true
          this.loading = false
      }
    },
    refresh() {
      this.$refs.rtcVideo.refresh()
    },
    fullScreen() {
      //  设置浏览器全屏
      if (!this.fullScreenType) {
      var element = document.documentElement
					if (element.requestFullscreen) {
						element.requestFullscreen()
					} else if (element.webkitRequestFullScreen) {
						element.webkitRequestFullScreen()
					} else if (element.mozRequestFullScreen) {
						element.mozRequestFullScreen()
					} else if (element.msRequestFulldcreen) {
						element.msRequestFulldcreen()
					}
          this.fullScreenType = true
      } else {
      // 取消全屏
      if (document.exitFullscreen) {
						document.exitFullscreen()
					} else if (document.webkitCancelFullScreen) {
						document.webkitCancelFullScreen()
					} else if (document.mozCancelFullScreen) {
						document.mozCancelFullScreen()
					} else if (document.msExitFulldcreen) {
						document.msExitFulldcreen()
					}
          this.fullScreenType = false
     }
  }
}
}
</script>

<style lang="less">
.video-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  background: #000;
  .ant-spin-dot-item {
    background-color: #fff;
  }
  .ant-spin-text {
    color: #fff;
  }
  .player {
    width: 100%;
    height: 100%;
  }
  .video-switch {
    position: absolute;
    right: 3px;
    top: 3px;
    z-index: 9;
  }
  .video-mask {
    color: #c4c4c4;
    text-align: center;
    padding-top: 25px;
    font-size: 12px;
    position: absolute;
    top: 25%;
    left: 45%;
  }
  .direction {
    font-size: 12px;
    position: absolute;
    left: 3px;
    top: 3px;
    color: #fff;
    background: #013a07;
    line-height: 16px;
    display: block;
    width: 16px;
    text-align: center;
  }
  .video-set {
    left: 20px;
    cursor: pointer;
    z-index: 5;
  }
  .ant-spin-nested-loading {
    height: 100%;
    .ant-spin-container {
      height: 100%;
    }
  }
}
.monitor-video-item:nth-child(2),
.monitor-video-item:last-child {
  .video-switch {
    left: 3px;
  }
  .direction {
    left: auto;
    right: 3px;
  }
  .video-set {
    left: auto;
    right: 3px;
  }
}
.video-tool {
  position: absolute;
  bottom: 5px;
  right: 0;
  z-index: 99;
  display: flex;
  .tool-item {
    margin-right: 10px;
    cursor: pointer;
    .img {
      width: 25px;
      height: 25px;
    }
  }
}
</style>
