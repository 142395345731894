var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "videoModulElement",
      staticClass: "video-wrapper",
      on: {
        mouseover: function ($event) {
          _vm.showTools = true
        },
        mouseout: function ($event) {
          _vm.showTools = false
        },
      },
    },
    [
      _c(
        "a-spin",
        { attrs: { tip: "视频加载中...", spinning: _vm.loading } },
        [
          _vm.videoType === "rtmp" &&
          _vm.playing &&
          !_vm.isLive &&
          _vm.reLoadVideo
            ? _c("video", {
                ref: "videoElement-" + _vm.index,
                staticClass: "video-js player",
              })
            : _vm._e(),
          _vm.videoType === "rtmp" && _vm.isLive && _vm.url
            ? _c("ali-player", {
                ref: "player",
                staticClass: "player",
                attrs: { options: _vm.options, source: _vm.url },
                on: {
                  ready: _vm.handleReady,
                  error: _vm.handleError,
                  ended: _vm.handleEnded,
                },
              })
            : _vm._e(),
          _vm.videoType === "rtc" && _vm.playing
            ? _c("RtcVideoPlay", {
                ref: "rtcVideo",
                attrs: {
                  vehicleId: _vm.vehicleId,
                  rtcInfo: _vm.rtcInfo,
                  vehicleCameraDeviceInfo: _vm.vehicleCameraDeviceInfo,
                },
                on: { onConnectType: _vm.onConnectType },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.videoType === "rtc"
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showTools,
                  expression: "showTools",
                },
              ],
              staticClass: "video-tool",
            },
            [
              _vm.showScreenBtn
                ? _c(
                    "div",
                    {
                      staticClass: "tool-item",
                      attrs: { title: "全屏" },
                      on: {
                        mouseover: function ($event) {
                          _vm.showTools = true
                        },
                        click: function ($event) {
                          return _vm.fullScreen()
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "img",
                        attrs: {
                          src: require("@/assets/images/video/full-screen.png"),
                          alt: "",
                        },
                      }),
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "tool-item",
                  attrs: { title: "刷新" },
                  on: {
                    mouseover: function ($event) {
                      _vm.showTools = true
                    },
                    click: function ($event) {
                      return _vm.refresh()
                    },
                  },
                },
                [
                  _c("img", {
                    staticClass: "img",
                    attrs: {
                      src: require("@/assets/images/video/refresh.png"),
                      alt: "",
                    },
                  }),
                ]
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }