import { render, staticRenderFns } from "./RevoVideoPlayer.vue?vue&type=template&id=285e3c1e&"
import script from "./RevoVideoPlayer.vue?vue&type=script&lang=js&"
export * from "./RevoVideoPlayer.vue?vue&type=script&lang=js&"
import style0 from "./RevoVideoPlayer.vue?vue&type=style&index=0&lang=less&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/RevolutionCloud_ra-web-admin_pF1p/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('285e3c1e')) {
      api.createRecord('285e3c1e', component.options)
    } else {
      api.reload('285e3c1e', component.options)
    }
    module.hot.accept("./RevoVideoPlayer.vue?vue&type=template&id=285e3c1e&", function () {
      api.rerender('285e3c1e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/monitor/monitorForm/vehicle/funcList/modules/RevoVideoPlayer.vue"
export default component.exports